@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Roboto+Condensed&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:wght@400;500;600;700&family=Smooch+Sans:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Pacifico&family=Roboto+Condensed&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:wght@400;500;600;700&family=Smooch+Sans:wght@100&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/* to remove the blue highlight of button on mobile when click */

body {
  @apply font-MainFont;
}

.btn-nav {
  @apply text-secondary rounded-full hover:text-white hover:bg-secondary py-[2px] px-3 capitalize text-[0.8rem] sm:text-sm font-bold cursor-pointer tracking-wider  transition ease-out duration-500;
}
.navmenu {
  @apply cursor-pointer;
}

/* ###############  HEADER BANNER ################ */
.header-banner {
  @apply relative w-full h-[8rem] sm:h-[10rem] sm:rounded-xl bg-secondary overflow-hidden;
}

/*################### SIDE_BAR ################ */
.sideBar-btn {
  @apply ease-out duration-500;
}

/* ################# SCROLLER STYLE ########### */

body::-webkit-scrollbar {
  width: 0.4rem;
  height: 8px;
}

body::-webkit-scrollbar-track {
  background-color: #e3e4e6;
  border-radius: 150px;
}

body::-webkit-scrollbar-thumb {
  background-color: #ee5873;
  border-radius: 100px;
}

/*############### RECIPE POST ####################*/
.card {
  @apply bg-white rounded overflow-hidden shadow-md relative;
}

/* ########### SIDEBAR STYLE ############### */
.sidebar {
  position: fixed;
  overflow: hidden;
  left: 0;
  width: 67px;
  height: 100%;
  background: #e3e4e6;
  transition: width 0.4s;
  /* overflow-y: hidden; */
}

.open.sidebar {
  width: 187px;
  /* width: 230px; */
}

.sidebarinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 90%;
  /* background: url(./assets/mesob1.png) no-repeat center; */
  background-size: cover;
}
.sidebar::before {
  content: "";
  background-image: url(./assets/mesob1.png);
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: -158px;
  opacity: 0.35;
}

.sidebarburger {
  width: 72px;
  height: 72px;
  display: grid;
  place-items: center;
  background: url(./assets/iconburger.svg) no-repeat center center;
}

.sidebarmenu {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 7px;
}

.sidebarmenu > a {
  display: flex;
  gap: 25px;
  align-items: center;
  height: 60px;
  width: 100%;
  font-size: 16px;
  font-weight: 800;
  /* font-weight: 700; */
  /* letter-spacing: 2px; */
  line-height: 1;
  padding: 0 23px;
  text-transform: capitalize;
  font-style: italic;
  background-color: #e3e4e67a;
}

.sidebarmenu > a:hover {
  color: black;
}

.sidebar_footer {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}
.open .sidebar_footer {
  visibility: visible;
  opacity: 1;
}

/*############# Head Bnner ############## */
@media (min-width: 768px) {
  .main-section {
    position: relative;
    min-height: 90vh;
    top: 0;
    left: 67px;
    width: calc(100% - 67px);
    transition: all 0.4s ease;
    z-index: -1;
  }
  .sidebar.open ~ .main-section {
    width: calc(100% - 187px);
  }
}

.sidebar.open ~ .main-section {
  left: 187px;
}

/* ###### list categories ####### */
.categories::-webkit-scrollbar {
  height: 0.3rem;
}

.categories::-webkit-scrollbar-track {
  background-color: bg-gray-50;
  border-radius: 100%;
}

.categories::-webkit-scrollbar-thumb {
  background-color: #f8b7c3;
  border-radius: 100px;
}
.categories::-webkit-scrollbar-thumb:hover {
  background-color: #ee5873;
}
.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

@media (max-width: 640px) {
  .categories::-webkit-scrollbar {
    display: none;
  }
  .categories:first-child {
    padding-left: 10px;
  }
  .categories:last-child {
    padding-right: 10px;
  }
}

/* ######## Recipes Post #########*/

@media (max-width: 448px) {
  .recipes_post_size {
    height: 15rem;
  }
}
@media (min-width: 448px) {
  .recipes_post_size {
    height: 18rem;
  }
}

/* ######## Modal ##########*/
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.read-or-hide {
  color: rgb(192, 192, 192);
  cursor: pointer;
}

/* Signup modal */
.PhoneInput {
  --PhoneInputCountryFlag-height: 1.3em;
  --PhoneInputCountryFlag-borderColor: none;
}
.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}
.invalid label {
  color: #b40e0e !important;
}
.invalid textarea {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}
.invalid .category {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.singup_Input {
  @apply bg-white border outline-2 outline-gray-300 border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5;
}

/* contact us */
.section-title {
  text-align: center;
  padding-bottom: 30px;
  padding-top: 15px;
}
.section-title h2 {
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #37517e;
}
.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}
.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #37517e;
  bottom: 0;
  left: calc(50% - 20px);
}

/* ################## user Page ###################### */

.switch-btn {
  @apply w-1/2 text-center sm:py-2 py-1 capitalize border-light2 text-secondary  bg-primary hover:bg-light;
}
.switch-btn:not(:last-child) {
  border-right: 1px solid #fef3f5;
}
.switch-btn-active {
  background-color: #f8b7c3;
}

/* ################ ADD RECIPE ################ */
.add-recipe-input {
  @apply block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-500 focus:outline-none;
}
.add-btn {
  @apply bg-secondary py-2 px-8 rounded-md text-white hover:bg-btnHover hover:shadow-md font-bold;
}
.select-input {
  @apply appearance-none mr-1  block w-full text-sm px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-500 focus:outline-none;
}
.option-select {
  @apply block  text-sm px-2 py-1.5 font-normal text-gray-700 bg-white  border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-500 focus:outline-none;
}
.remove-list-btn {
  @apply text-slate-400 hover:text-slate-500 absolute inset-y-0 right-0 pr-1 flex items-center cursor-pointer transition ease-out duration-500;
}
.add-list-btn {
  @apply text-xs bg-slate-400 hover:bg-slate-400 hover:shadow-md py-2 px-4 rounded-md text-white mt-3;
}

/*  */
.MYspinner {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
