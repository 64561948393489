.tags_container {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  background-color: #ddd;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 5px;
  font-size: 14px;
  position: relative;
}

.remove_icon {
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: -10px;
  font-size: 12px;
  background-color: #f8b7c3;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 15px;
}

/* .tags_input {
  border: none;
  border-bottom: 2px solid #ddd;
  font-size: 14px;
  padding: 5px;
  margin: 5px;
  flex-grow: 1;
} */
