/* Select Ctaegory */
.categoryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.categorySelect {
  padding: 10px;
  margin-top: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 200px;
}

.categoryError {
  margin-top: 10px;
  font-size: 14px;
  color: red;
}
