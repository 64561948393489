.recipe_ingredient ul > li::before {
  content: "";
  margin-left: -0.8125rem;
  margin-top: 0.75rem;
  width: 0.3125rem;
  height: 0.3125rem;
  background: #ee5873;
  position: absolute;
  border-radius: 50%;
}
